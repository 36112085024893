/**
 * 本ファイルは next.config.js, sentry.*.config.js 等の
 * JSファイルから読み込まれることを想定してmjsファイルとしている
 */
import { createEnv } from '@t3-oss/env-nextjs';
import { z } from 'zod';

export const env = createEnv({
  // バックエンドのみで利用可能な環境変数
  server: {
    BASE_URL: z.string().min(1).default('https://www.shands.jp'),
    // Basic認証
    BASIC_AUTH_USER: z.string().optional(),
    BASIC_AUTH_PASS: z.string().optional(),
    // Sentry
    SENTRY_ORG: z.string().min(1).default('shakehands'),
    SENTRY_PROJECT: z.string().min(1).default('shands'),
    SENTRY_AUTH_TOKEN: z.string().min(1).optional(),
    // CMS
    CMS_SECRET: z.string().min(1),
    CMS_API_KEY: z.string().min(1),
    CMS_API_ENDPOINT: z
      .string()
      .min(1)
      .default('https://cms.shands.jp/graphql-internal'),
    CMS_API_BASIC_AUTH_USER: z.string().min(1).optional(),
    CMS_API_BASIC_AUTH_PASS: z.string().min(1).optional(),
    // Vercel
    VERCEL_URL: z.string().min(1).optional().default('localhost:3000'),
    VERCEL_GIT_COMMIT_SHA: z.string().min(1).default('dummy-sha'),
  },
  // バックエンド・フロントエンドの両方で利用可能な環境変数
  client: {
    // Sentry
    NEXT_PUBLIC_SENTRY_DSN: z
      .string()
      .min(1)
      .default(
        'https://f0f3973b4a284744ab725dc903cf3dc3@o1244440.ingest.sentry.io/6414385',
      ),
    NEXT_PUBLIC_SENTRY_ENVIRONMENT: z.string().min(1).default('pro'),
    // myshands
    NEXT_PUBLIC_MYSHANDS_API_ENDPOINT: z.string().min(1),
    // Vercel
    NEXT_PUBLIC_VERCEL_URL: z
      .string()
      .min(1)
      .optional()
      .default('localhost:3000'),
    NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA: z.string().min(1).default('dummy-sha'),
  },
  // server,clientセクションに含まれるすべての環境変数を記述する必要がある
  runtimeEnv: {
    BASE_URL: process.env.BASE_URL,
    // Basic認証
    BASIC_AUTH_USER: process.env.BASIC_AUTH_USER,
    BASIC_AUTH_PASS: process.env.BASIC_AUTH_PASS,
    // Sentry
    SENTRY_ORG: process.env.SENTRY_ORG,
    SENTRY_PROJECT: process.env.SENTRY_PROJECT,
    SENTRY_AUTH_TOKEN: process.env.SENTRY_AUTH_TOKEN,
    // CMS
    CMS_SECRET: process.env.CMS_SECRET,
    CMS_API_KEY: process.env.CMS_API_KEY,
    CMS_API_ENDPOINT: process.env.CMS_API_ENDPOINT,
    CMS_API_BASIC_AUTH_USER: process.env.CMS_API_BASIC_AUTH_USER,
    CMS_API_BASIC_AUTH_PASS: process.env.CMS_API_BASIC_AUTH_PASS,
    // Vercel
    VERCEL_URL: process.env.VERCEL_URL,
    VERCEL_GIT_COMMIT_SHA: process.env.VERCEL_GIT_COMMIT_SHA,
    // Sentry
    NEXT_PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
    NEXT_PUBLIC_SENTRY_ENVIRONMENT: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
    // myshands
    NEXT_PUBLIC_MYSHANDS_API_ENDPOINT:
      process.env.NEXT_PUBLIC_MYSHANDS_API_ENDPOINT,
    // Vercel
    NEXT_PUBLIC_VERCEL_URL: process.env.NEXT_PUBLIC_VERCEL_URL,
    NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA:
      process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
  },
});
